import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import { Fonts, Container, NotFoundPage } from '../global.css';
import BackToTop from '../components/back-to-top/back-to-top';
import axios from 'axios';
import NProgress from 'nprogress';
import { trialDetailsUrl } from '../constants/config';

const NotFound = ({ pageContext }) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      let { href } = window.location;
      if (href) {
        const match = `${href}`.match(/([A-Z]*)([-_/]*)\d+([-_/]*)([A-Z]*)\d+/);
        if (match && match.length) {
          if (match[0].replace('/', '').trim() < 25000) {
            axios
              .get(
                trialDetailsUrl(`${match[0].replace('/', '').trim()}`, 'old')
              )
              .then(() => {
                NProgress.done();
                setTimeout(() => {
                  window.location.href = `/${
                    pageContext.langKey
                  }/study/?id=${match[0].replace('/', '').trim()}&oldId=true`;
                }, 500);
              })
              .catch(() => {
                setCanRender(true);
              });
            return;
          } else {
            axios
              .get(trialDetailsUrl(`${match[0].replace('/', '').trim()}`))
              .then(() => {
                NProgress.done();
                setTimeout(() => {
                  window.location.href = `/${
                    pageContext.langKey
                  }/study/?id=${match[0].replace('/', '').trim()}`;
                }, 500);
              })
              .catch(() => {
                setCanRender(true);
              });
            return;
          }
        }
      }
    }
    setCanRender(true);
  }, []);

  return canRender ? (
    <Layout>
      <Container>
        <Fonts>
          <NotFoundPage>
            <h1>Page not found</h1>
            <p>If you entered a web address please check it was correct.</p>
            <p>
              You can also try <a href={'/'}>home page</a> to find the
              information you need.
            </p>
          </NotFoundPage>
          <BackToTop />
        </Fonts>
      </Container>
    </Layout>
  ) : null;
};

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
};
